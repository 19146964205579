import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-slider"
export default class extends Controller {
  currentColumn = null
  dotButtons = null
  nextButton = null
  prevButton = null
  scrollOptions = { behavior: 'smooth', block: 'nearest', inline: 'end' }
  stickyColumn = null
  stickyColumnWidth = null
  tableCols = null
  touchStartX = null

  initialize () {
    // Do nothing if there is a Trix editor on the page or table has no scroll
    const trixEditorExists = document.querySelector('[data-controller="trix"]')
    const hasNoScroll = !this.hasHorizontalScrollbar(this.element)
    if (trixEditorExists || hasNoScroll) return

    this.element.id = 'TableSlider-js'
    this.tableCols = this.element.querySelectorAll('table tr:first-child td:not(:first-child)')
    this.stickyColumn = this.element.querySelector('table tr:first-child td:first-child').getBoundingClientRect()
    this.stickyColumnWidth = this.stickyColumn.width

    this.element.after(this.createScrollBar())

    // Initial buttons state setup
    this.updateButtonStates(this.tableCols[0])

    // Add swipe logic
    this.element.addEventListener('touchstart', this.touchStartListener.bind(this), false)
    this.element.addEventListener('touchmove', this.touchMoveListener.bind(this), false)
    this.element.addEventListener('touchend', () => { this.touchStartX = null }, false)

    // Set up Intersection Observer
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting ) {
          this.currentColumn = entry.target
          this.updateButtonStates(entry.target)
        }
      })
    }, { threshold: 0.7, rootMargin: `0px 0px 0px -${this.stickyColumnWidth}px`, root: this.element })

    // Observe all columns
    this.tableCols.forEach(column => {
      this.intersectionObserver.observe(column)
    })
  } // end of initializate

  createDotsBar () {
    const dotsBar = document.createElement('div')
    dotsBar.classList.add('dotsBar')

    this.tableCols.forEach((element) => {
      const btn = this.createButton('dotBtn', () => {
        element.scrollIntoView(this.scrollOptions)
      })
      dotsBar.appendChild(btn)
    })
    this.dotButtons = dotsBar.querySelectorAll('.dotBtn')
    return dotsBar
  }

  createScrollBar () {
    const scrollBar = document.createElement('div')
    this.nextButton = this.createButton('nextBtn', this.scrollNext.bind(this))
    this.prevButton = this.createButton('prevBtn', this.scrollPrev.bind(this))

    scrollBar.classList.add('rnk-TableButtonBar')
    scrollBar.appendChild(this.prevButton)
    scrollBar.appendChild(this.createDotsBar())
    scrollBar.appendChild(this.nextButton)

    return scrollBar
  }

  createButton (classlist, listener) {
    const btn = document.createElement('button')
    btn.classList.add(classlist)
    if (listener) {
      btn.addEventListener('click', listener)
    }
    return btn
  }

  hasHorizontalScrollbar (element) {
    return element.scrollWidth > element.clientWidth
  }

  scrollNext () {
    this.currentColumn?.nextElementSibling?.scrollIntoView(this.scrollOptions)
  }

  scrollPrev () {
    this.currentColumn?.previousElementSibling?.scrollIntoView(this.scrollOptions)
  }

  touchMoveListener (event) {
    if (!this.touchStartX) return

    const touchMoveX = event.touches[0].clientX
    const differenceX = this.touchStartX - touchMoveX

    if (Math.abs(differenceX) > 50) {
      if (differenceX > 0) {
        this.scrollNext()
      } else {
        this.scrollPrev()
      }
      this.touchStartX = null
    }
  }

  touchStartListener (event) {
    this.touchStartX = event.touches[0].clientX
  }

  updateButtonStates (activeColumn) {
    for (let i = 0; i < this.tableCols.length; i++) {
      this.dotButtons[i].disabled = this.tableCols[i] === activeColumn
    }
    this.prevButton.disabled = this.tableCols[0] === activeColumn
    this.nextButton.disabled = this.tableCols[this.tableCols.length -1] === activeColumn
  }
}
